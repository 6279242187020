import { useEffect } from "react";
import AOS from "aos";
import {
  Footer,
  Navbar,
  MainContent,
  ContactContent,
} from "./ExportComponents";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

function App() {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  const routes = [
    { path: "/", component: <MainContent /> },
    { path: "/contact", component: <ContactContent /> },
  ];

  return (
    <Router>
      <Navbar />
      <Routes>
        {routes.map((route, i) => (
          <Route path={route.path} element={route.component} key={i} />
        ))}
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
