function MainContent() {
  const specialties = ["Machine Learning & AI", "Computer Vision", "Generative Models", "Stable Diffusion", "Fast AI"];
  return (
    <section className="hero hero-bg">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="hero-text">
              {specialties.map((i) => (
                <h1 className="text-white" data-aos="fade-up" key={i}>
                  {i}
                </h1>
              ))}

              <a
                href="/contact"
                className="custom-btn btn-bg btn mt-3"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                Contact Us
              </a>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="hero-image" data-aos="fade-up" data-aos-delay="300">
              <img src="working-girl.png" className="img-fluid" alt="working" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default MainContent;
