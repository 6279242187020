function ContactContent() {
  return (
    <section className="contact section-padding">
      <div className="container">
        <div className="row">
          <div className="mx-auto py-5" data-aos="fade-up">
            <p>
              email us at{" "}
              <a href="mailto:sales@liquidthought.com">
                sales@liquidthought.com
              </a>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ContactContent;
