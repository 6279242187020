import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faHome } from "@fortawesome/free-solid-svg-icons";

function Footer() {
  const footerData = [
    {
      header: "Contact Info",
      data: "sales@liquidthought.com",
      icon: faEnvelope,
    },
    {
      header: "Our Office",
      data: "Chantilly, VA (Metro DC)",
      icon: faHome,
    },
  ];
  return (
    <footer className="site-footer">
      <div className="container">
        <div className="row">
          <div className="col-lg-5 mx-lg-auto col-md-8 col-10">
            <p className="my-4" data-aos="fade-up" data-aos-delay="100">
              Copyright &copy; 2023 LiquidThought LLC
            </p>
          </div>

          {footerData.map((option, i) => (
            <div
              className="col-lg-3 col-md-6 col-12"
              data-aos="fade-up"
              data-aos-delay="200"
              key={i}
            >
              <h4 className="my-4">{option.header}</h4>
              <p>
                <FontAwesomeIcon
                  className="mr-2 footer-icon"
                  icon={option.icon}
                />
                {option.data}
              </p>
            </div>
          ))}
        </div>
      </div>
    </footer>
  );
}

export default Footer;
