function Navbar() {
  const navItems = [
    {
      header: "Blog",
      href: "https://www.storminthecastle.com",
      className: "nav-link",
    },
    {
      header: "Contact",
      href: "/contact",
      className: "nav-link contact",
    },
  ];
  return (
    <nav className="navbar navbar-expand-lg">
      <div className="container">
        <a className="navbar-brand" href="/">
          <img src="lt_logo.png" alt="Liquid Thought LLC logo" />
        </a>

        <ul className="navbar-nav ml-auto">
          {navItems.map((navItem, i) => (
            <li className="nav-item" key={i}>
              <a href={navItem.href} className={navItem.className}>
                {navItem.header}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </nav>
  );
}

export default Navbar;
